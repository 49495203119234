import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Home from './domain/Home';
import Cupon from './domain/Cupon';
import Comprobante from './domain/Comprobante';

function App() {
    const inputElement = React.useRef();

    useEffect(() => {
        window.location.replace('https://mitta.cl');
    });

    return (
        <Router>
            <ScrollToTop />

            <Header />

            <Switch>
                <Redirect from='//*' to='/*' />
                <Route exact path='/' component={Home} />
                <Route path='/cupon/:codigo' component={Cupon} />
                <Route path='/comprobante' component={Comprobante} />
            </Switch>

            <Footer />

            <div
                id='clickfono-widget'
                title='Te llamamos ahora'
                ref={inputElement}
            >
                <div id='icon' className=''></div>
                <div id='rectangle-rounded'>
                    <div id='text'>Te llamamos ahora</div>
                </div>
            </div>
        </Router>
    );
}

export default App;
