import api from '../../config/api';

const checkTransaccionAprobadaByOc = async (oc) => {

    try {
        const response = await api.mainAxios.get(`/HubPago/CheckTransaccionAprobadaByOcAsync/${oc}`);

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};

export default {
    checkTransaccionAprobadaByOc
};