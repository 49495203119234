import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Carrusel = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
            slidesToSlide: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    return (
        <>
            <section className='titulo_interno'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='col-md-12'>
                            <h3 className='titulo_dentro'>
                                ¿Preparado para usar tu cupón?
                            </h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container-fluid productos_seis'>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    removeArrowOnDeviceType={['tablet', 'mobile']}
                >
                    <div className='card'>
                        <img
                            className='img-fluid'
                            src={`${process.env.PUBLIC_URL}/img/cyber-mitta-en-chile-descuentos-exclusivos-en-arriendo-de-auto-suzuki-swift.jpg`}
                            alt='Cupón Descuento Noviembre 2024'
                        />
                        <div className='card-body text-center p-0'>
                            <h6 className='card-title font-weight-bold'>
                                <span className='text-uppercase'>
                                    Suzuki Swift 1.2
                                </span>
                            </h6>
                            <p className='card-text'>
                                <small>
                                    <span className='text-uppercase'>
                                        <strong>CLASE A </strong>
                                    </span>
                                    (o similiar)
                                </small>
                            </p>
                            <p className='card-text bg-negro m-0 p-2 text-white'>
                                *Valor ref. fin de semana /$53.255
                            </p>
                            <p className='card-text bg-red text-white m-0 p-2'>
                                Cupón descuento $39.000
                            </p>

                            <Link to='/cupon/60?utm_source=Trafico+directo&utm_medium=Landing&utm_campaign=MittaBlack&utm_content=Oferta_especial_SUZUKISWIFT1.2'>
                                <button className='btn_comprar m-4'>
                                    Comprar
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className='card'>
                        <img
                            className='img-fluid'
                            src={`${process.env.PUBLIC_URL}/img/cyber-mitta-en-chile-descuentos-exclusivos-en-rent-a-car-toyota-yaris-sport.jpg`}
                            alt='Cupón Descuento Noviembre 2024'
                        />
                        <div className='card-body text-center p-0'>
                            <h6 className='card-title font-weight-bold'>
                                <span className='text-uppercase'>
                                    Toyota Yaris Sport
                                </span>
                            </h6>
                            <p className='card-text'>
                                <small>
                                    <span className='text-uppercase'>
                                        <strong>CLASE B </strong>
                                    </span>
                                    (o similiar)
                                </small>
                            </p>
                            <p className='card-text bg-negro m-0 p-2 text-white'>
                                *Valor ref. 2 días /$86.468
                            </p>
                            <p className='card-text bg-red text-white m-0 p-2'>
                                Cupón descuento $58.500
                            </p>

                            <Link to='/cupon/90?utm_source=Trafico+directo&utm_medium=Landing&utm_campaign=MittaBlack&utm_content=Oferta_especial_TOYOTAYARISSPORT'>
                                <button className='btn_comprar m-4'>
                                    Comprar
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className='card'>
                        <img
                            className='img-fluid'
                            src={`${process.env.PUBLIC_URL}/img/cyber-mitta-en-chile-descuentos-exclusivos-en-arriendo-de-auto-hyundai-accent.jpg`}
                            alt='Cupón Descuento Noviembre 2024'
                        />
                        <div className='card-body text-center p-0'>
                            <h6 className='card-title font-weight-bold'>
                                <span className='text-uppercase'>
                                    Hyundai Accent 1.4 MT
                                </span>
                            </h6>
                            <p className='card-text'>
                                <small>
                                    <span className='text-uppercase'>
                                        <strong>CLASE C </strong>
                                    </span>
                                    (o similiar)
                                </small>
                            </p>
                            <p className='card-text bg-negro m-0 p-2 text-white'>
                                *Valor ref. 2 días /$96.966
                            </p>
                            <p className='card-text bg-red text-white m-0 p-2'>
                                Cupón descuento $78.000
                            </p>

                            <Link to='/cupon/120?utm_source=Trafico+directo&utm_medium=Landing&utm_campaign=MittaBlack&utm_content=Oferta_especial_HYUNDAIACCENT1.4MT'>
                                <button className='btn_comprar m-4'>
                                    Comprar
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className='card'>
                        <img
                            className='img-fluid'
                            src={`${process.env.PUBLIC_URL}/img/cyber-mitta-en-Chile-descuentos-exclusivos-en-arriendo-de-auto-toyota-corolla-cross.jpg`}
                            alt='Cupón Descuento Noviembre 2024'
                        />
                        <div className='card-body text-center p-0'>
                            <h6 className='card-title font-weight-bold'>
                                <span className='text-uppercase'>
                                    Toyota Corolla Cross XEI 1.8
                                </span>
                            </h6>
                            <p className='card-text'>
                                <small>
                                    <span className='text-uppercase'>
                                        <strong>CLASE R2 </strong>
                                    </span>
                                    (o similiar)
                                </small>
                            </p>
                            <p className='card-text bg-negro m-0 p-2 text-white'>
                                *Valor ref. 2 días /$127.923
                            </p>
                            <p className='card-text bg-red text-white m-0 p-2'>
                                Cupón descuento $97.500
                            </p>

                            <Link to='/cupon/150?utm_source=Trafico+directo&utm_medium=Landing&utm_campaign=MittaBlack&utm_content=Oferta_especial_TOYOTACOROLLACROSSXEI'>
                                <button className='btn_comprar m-4'>
                                    Comprar
                                </button>
                            </Link>
                        </div>
                    </div>
                </Carousel>
            </section>

            <br />
            <section className='nota_pie'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='col-md-12 nota_dentro'>
                            <ul className='asterisk'>
                                <li>
                                    Tarifas referenciales con IVA incluído. Las
                                    tarifas diarias incluyen un descuento del
                                    15%.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Carrusel;
