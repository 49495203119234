import api from '../../config/api';

const getByOcForComprobante = async (oc) => {

    try {
        const response = await api.mainAxios.get(`/Clientes/GetByOcForComprobanteAsync/${oc}`);

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};

const getComprobanteByOc = async (oc) => {

    try {
        const response = await api.mainAxios.get(`/Clientes/GetComprobanteByOcAsync/${oc}`);

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};


const checkOcPagada = async (oc) => {

    try {
        const response = await api.mainAxios.get(`/Clientes/CheckOcPagadaAsync/${oc}`);

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};

const payOc = async (params) => {

    try {
        const response = await api.mainAxios.post('/Clientes/PayOcAsync', params);

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};

const finishOC = async (params) => {

    try {
        const response = await api.mainAxios.put('/Clientes/FinishOcAsync', params);

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};

const sendFormHubSpotNoPago = async (params) => {

    try {
        const response = await api.mainAxios.post('/Clientes/SendFormHubSpotNoPagoAsync', params);

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};

export default {
    getByOcForComprobante,
    getComprobanteByOc,
    checkOcPagada,
    payOc,
    finishOC,
    sendFormHubSpotNoPago
};