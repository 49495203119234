import axios from 'axios';

axios.defaults.timeout = 30000;

const mainAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL    
});

const api = {
    mainAxios
};

export default api;