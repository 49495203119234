export const ERROR_GENERICO = 'Ha habido un error en la operación, por favor intenta nuevamente';

export const CUPON_SIN_STOCK = 'Cupón sin stock';

export const CUPON_NO_DISPONIBLE = 'Cupón no disponible';

export const ORDEN_COMPRA_PAGADA = 'La orden de compra ya registra un pago';

export const CAMPO_REQUERIDO = 'Este campo es requerido';

export const RUT_NO_VALIDO = 'Rut no es válido';

export const EMAIL_NO_VALIDO = 'Correo no es válido';

export const EMAIL_NO_COINCIDE = 'Correo no coincide';

export const CELULAR_NO_VALIDO = 'Celular no es válido';
