import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clientesApi from '../services/api/clientesApi';
import hubPagoApi from '../services/api/hubPagoApi';
import BreadCrumb from '../components/BreadCrumb';
import Error from '../components/Error';
import Loader from '../components/Loader';
import {
    ERROR_GENERICO,
    ORDEN_COMPRA_PAGADA,
} from '../constants/mensajesConst';
import logoCyberWhiteImage from '../assets/img/logo-cyber-white.svg';
import cdOficialImage from '../assets/img/cd_oficial.png';

const Comprobante = () => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [passedQueue, setPassedQueue] = useState(true);

    /** Efecto para validar sala de espera */
    useEffect(() => {
        window.addEventListener('queuePassed', handleQueueEventListener);

        return () => {
            window.removeEventListener('queuePassed', handleQueueEventListener);
        };
    }, []);

    useEffect(() => {
        /** Valida cookie de la sala de espera */
        window.QueueIt.validateUser();
    }, [passedQueue]);

    useEffect(() => {
        fetchFinalizarOc();
    }, []);

    const handleQueueEventListener = (e) => {
        setPassedQueue(true);
    };

    const fetchFinalizarOc = async () => {
        try {
            setLoading(true);

            /** Obtiene oc desde el localStorage */
            const oc = localStorage.getItem('oc');

            /** Verifica que exita la oc en el localstorage */
            if (!oc) {
                setError(ERROR_GENERICO);
                return;
            }

            /** Verifica si el hub de pago registra una transaccción aprobada para la oc */
            const responseHubPago =
                await hubPagoApi.checkTransaccionAprobadaByOc(oc);

            if (!responseHubPago.data) {
                await clientesApi.sendFormHubSpotNoPago({ oc: oc });

                setError(ERROR_GENERICO);
                return;
            }

            /** Verifica si el cyber registra la oc como pagada */
            const responseCyber = await clientesApi.checkOcPagada(oc);

            if (responseCyber.data) {
                setError(ORDEN_COMPRA_PAGADA);
                return;
            }

            /** Finaliza comprobante */
            const params = {
                oc: oc,
            };

            await clientesApi.finishOC(params);

            /** Elimina la oc del localStorage para no volver a pagar */
            localStorage.removeItem('oc');
        } catch (error) {
            setError(ERROR_GENERICO);
        } finally {
            setLoading(false);
        }
    };

    const handleReservarClick = (e) => {
        window.location = 'https://www.mitta.cl';
    };

    if (error) return <Error error={error} />;

    return (
        <>
            {loading && <Loader />}

            <BreadCrumb step={3} />

            <div className='row altura_base finals banner-landing position-relative'>
                {/* <!-- ////// ELEMENTOS ALINEADOS A LA DERECHA - CUPON 50% ////// --> */}
                <div className='col-md-4 float-right text-center text-right  m-4 view-a no-view-a top-ultimahora'>
                    <h1 className='text-white display-3 font-weight-bold'>
                        ¡Gracias por tu compra!
                    </h1>

                    {/* <h1 className='text-white font-weight-bold pb-3 txt-ultimahora'>
                        Por tu compra en el
                        <br />
                        CyberMonday MITTA
                    </h1> */}
                </div>

                {/* <!-- ////// FIN////// --> */}

                {/* <!-- ////// Rent a Car Leasing Operativo ////// --> */}
                <div className='col col_20 cot_container_radio left semi-circle'>
                    <div className='col_60'>
                        <div className='title-circle'>
                            Rent a
                            <br /> Car
                        </div>
                    </div>
                </div>
                {/* <!-- ////// FIN ////// --> */}

                {/* <div className='col-md-4 barra-inferior2-border pb-4 no-view-a text-center pr-5'>
                    <div>
                        <img className='img-15 pb-2' src={cdOficialImage} alt='' />{' '}
                        <img className='img-40 pb-3' src={logoCyberWhiteImage} alt='' />
                    </div>
                    <span className='w-50 text-white w-50 rounded-lg border border-white ml-3'>30 de septiembre, 01 y 02 de octubre</span>
                </div> */}
            </div>

            {/* <!-- ////// ELEMENTOS RESPONSIVE TABLET A MOVIL ////// --> */}
            <div className='row probando view-b no-view-b'>
                {/* <div className='col-lg-6 text-center blue-cyber pt-2 pb-2'>
                    <img className='img-fluid w-50 pr-1' src={logoCyberWhiteImage} alt='' />
                    <img className='img-fluid w-25' src={cdOficialImage} alt='' />
                </div> */}

                <div className='col-md-12  text-white bg-red text-center pl-3 pr-3 pb-3 pt-3'>
                    <h1 className='font-weight-bold  display-4 text-white txt'>
                        ¡Gracias por tu compra!
                    </h1>
                    {/* <h1 className='text-white p-1 txt'>
                        <span className='font-weight-light'>
                            Por tu compra en el{' '}
                        </span>
                        <br />
                        <strong>CyberMonday</strong> MITTA
                    </h1> */}
                </div>
            </div>
            {/* <!-- ////// ELEMENTOS RESPONSIVE TABLET A MOVIL END ////// --> */}

            <section className='container-fluid bg-negro pt-2'>
                <div className='row'>
                    <div className='col-md-6 text-right mt-3 mb-3 text-mobile-center'>
                        <button
                            className='btn btn-primary text-uppercase  pl-5 pr-5'
                            onClick={handleReservarClick}
                        >
                            Reserva tu vehículo
                        </button>
                    </div>

                    <div className='col-md-6 text-left mt-3 mb-3 text-mobile-center'>
                        <Link to='/'>
                            <button className='btn btn-primary text-uppercase  pl-5 pr-5'>
                                Seguir comprando
                            </button>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Comprobante;
