import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useForm } from 'react-hook-form';
import Recaptcha from 'react-google-invisible-recaptcha';
import cuponesApi from '../services/api/cuponesApi';
import clientesApi from '../services/api/clientesApi';
import regionApi from '../services/api/regionApi';
import comunaApi from '../services/api/comunaApi';
import hubPagoApi from '../services/api/hubPagoApi';
import { formatMonto } from '../utils/numeroUtils';
import { formatearRut, limpiarRut, validarRut } from './../utils/rutUtils';
import { validarEmail } from './../utils/emailUtils';
import { validarCelular } from './../utils/telefonoUtils';
import { compareValues } from '../utils/ordenUtils';
import {
    ERROR_GENERICO,
    CUPON_SIN_STOCK,
    CAMPO_REQUERIDO,
    RUT_NO_VALIDO,
    EMAIL_NO_VALIDO,
    EMAIL_NO_COINCIDE,
    CELULAR_NO_VALIDO,
    ORDEN_COMPRA_PAGADA,
    CUPON_NO_DISPONIBLE,
} from '../constants/mensajesConst';
import BreadCrumb from '../components/BreadCrumb';
import Carrucel from '../components/Carrusel';
import Ayuda from '../components/Ayuda';
import Error from '../components/Error';
import Loader from '../components/Loader';
import CampaniaContext from '../contexts/campaniaContext';

const Cupon = () => {
    const { register, handleSubmit, errors, watch } = useForm({
        defaultValues: {
            deseaRecibirInfo: true,
        },
    });

    const { codigo } = useParams();
    const form = useRef(null);
    const recaptcha = useRef(null);

    const [ordenCompra, setOrdenCompra] = useState({
        ocCliente: (
            new Date().getTime().toString(36) + new Date().getUTCMilliseconds()
        ).toUpperCase(),
        rutCliente: '',
        nombreCliente: '',
        apellidoCliente: '',
        idCupon: 0,
        nombreCupon: '',
        valorCupon: '',
        porcentajeDescuentoCupon: 0,
        imageCupon: '',
        image2Cupon: '',
        stockCupon: 0,
    });

    const [regiones, setRegiones] = useState([]);
    const [comunas, setComunas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [documentos, setDocumentos] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [passedQueue, setPassedQueue] = useState(
        window.__queuePassed || true
    );

    const [campania, setCampania] = useState({
        vigenciaDesdeShortString: '',
        vigenciaHastaShortString: '',
        vigenciaDesdeLongString: '',
        vigenciaHastaLongString: '',
    });

    /** Efecto para validar sala de espera */
    useEffect(() => {
        window.addEventListener('queuePassed', handleQueueEventListener);

        return () => {
            window.addEventListener('queuePassed', handleQueueEventListener);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        /** Realiza llamada a api solo cuando la sala de espera realiza la redirección de vuelta */
        if (passedQueue) {
            fetchCupon();
            fetchRegiones();
        } else {
            window.QueueIt.validateUser();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codigo, passedQueue]);

    const handleQueueEventListener = (e) => {
        setPassedQueue(true);
    };

    const fetchCupon = async () => {
        try {
            setLoading(true);

            const responseId = await cuponesApi.getIdByCodigo(
                process.env.REACT_APP_ID_CAMPANIA,
                codigo
            );

            if (responseId.data > 0) {
                const id = responseId.data;
                const responseStock = await cuponesApi.hasStock(id);

                if (!responseStock.data) {
                    setError(CUPON_SIN_STOCK);

                    return;
                }

                const response = await cuponesApi.getById(id);

                setOrdenCompra({
                    ...ordenCompra,
                    idCupon: response.data.id,
                    nombreCupon: response.data.nombre,
                    valorCupon: response.data.valorCupon,
                    porcentajeDescuentoCupon: response.data.porcentajeDescuento,
                    imageCupon: response.data.image,
                    image2Cupon: response.data.image2,
                    stockCupon: response.data.stock,
                    montoDescuentoCupon: response.data.montoDescuento,
                    millas: response.data.millas,
                });

                setDocumentos([
                    ...documentos,
                    {
                        numeroDocumento: ordenCompra.ocCliente,
                        monto: response.data.valorCupon,
                        fecha: new Date().toISOString(),
                    },
                ]);

                const options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                };

                setCampania({
                    ...campania,
                    vigenciaDesdeShortString: new Date(
                        response.data.vigenciaDesde
                    ).toLocaleDateString('es-CL'),
                    vigenciaHastaShortString: new Date(
                        response.data.vigenciaHasta
                    ).toLocaleDateString('es-CL'),
                    vigenciaDesdeLongString: new Date(
                        response.data.vigenciaDesde
                    ).toLocaleDateString('es-CL', options),
                    vigenciaHastaLongString: new Date(
                        response.data.vigenciaHasta
                    ).toLocaleDateString('es-CL', options),
                });
            } else {
                setError(CUPON_NO_DISPONIBLE);
            }
        } catch (error) {
            setError(ERROR_GENERICO);
        } finally {
            setLoading(false);
        }
    };

    const fetchRegiones = async () => {
        try {
            const response = await regionApi.getAll();

            setRegiones(response.data.sort(compareValues('nombre')));
        } catch (error) {
            setError(ERROR_GENERICO);
        }
    };

    const fetchComunasByRegion = async (idRegion) => {
        try {
            const response = await comunaApi.getByRegion(idRegion);

            setComunas(response.data.sort(compareValues('nombre')));
        } catch (error) {
            setError(ERROR_GENERICO);
        }
    };

    const handleKeyUp = (e) => {
        let value = '';

        switch (e.target.name) {
            case 'rutCliente':
                value = formatearRut(e.target.value) || e.target.value;

                break;
            default:
                value = e.target.value;
        }

        e.target.value = value;
    };

    const handleChange = (e) => {
        let value = '';

        switch (e.target.name) {
            case 'idRegionCliente':
                value = e.target.value;
                fetchComunasByRegion(e.target.value);

                break;
            default:
                value = e.target.value;
        }

        setOrdenCompra({
            ...ordenCompra,
            [e.target.name]: value,
        });
    };

    const onSubmit = (data) => {
        recaptcha.current.execute().then(async (token) => {
            try {
                setSubmitting(true);

                /** Verifica si el hub de pago registra una transaccción aprobada para la oc */
                const responseHubPago =
                    await hubPagoApi.checkTransaccionAprobadaByOc(
                        ordenCompra.ocCliente
                    );

                if (responseHubPago.data) {
                    setError(ORDEN_COMPRA_PAGADA);
                    return;
                }

                const response = await cuponesApi.hasStock(ordenCompra.idCupon);

                if (!response.data) {
                    setError(CUPON_SIN_STOCK);
                    return;
                }

                const rutCliente = limpiarRut(data.rutCliente);
                const rut = rutCliente.substr(0, rutCliente.length - 1);
                const dv = rutCliente.slice(-1);

                const params = {
                    IdCupon: Number(ordenCompra.idCupon),
                    Oc: ordenCompra.ocCliente,
                    Rut: Number(rut),
                    Dv: dv,
                    Nombre: data.nombreCliente,
                    Apellido: data.apellidoCliente,
                    Direccion: data.direccionCliente,
                    IdComuna:
                        Number(data.idComunaCliente) > 0
                            ? Number(data.idComunaCliente)
                            : null,
                    Mail: data.mailCliente,
                    Celular: data.celularCliente,
                    DeseaRecibirInfo: data.deseaRecibirInfo,
                    JsonReq: JSON.stringify(data),
                };

                await clientesApi.payOc(params);

                /** Persiste oc para utilizarla al volver desde el hub de pago */
                localStorage.setItem('oc', ordenCompra.ocCliente);

                form.current.submit();
            } catch (error) {
                setError(ERROR_GENERICO);
                setSubmitting(false);
            }
        });
    };

    if (error) return <Error error={error} />;

    if (!passedQueue) return <Loader />;

    return (
        <>
            {loading && <Loader />}
            {submitting && (
                <Loader message='Usted será redirigido al resumen de su compra' />
            )}

            <BreadCrumb step={2} />

            <section className='titulo_interno'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='col-md-12'>
                            <h3 className='titulo_dentro'>Detalle de Compra</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container'>
                <div className='row'>
                    <div className='col-md-6 mb-4 bordes'>
                        <div className='card mx-auto'>
                            {ordenCompra.imageCupon && (
                                <img
                                    src={`${process.env.PUBLIC_URL}/img/${ordenCompra.imageCupon}`}
                                    className='card-img-top'
                                    alt='Cupón de descuento Mitta'
                                />
                            )}

                            <ul className='list-group list-group-flush'>
                                <li className='list-group-item bg-secondary text-white text-center'>
                                    Cupón{' '}
                                    <strong>
                                        $
                                        {formatMonto(
                                            ordenCompra.montoDescuentoCupon
                                        )}
                                    </strong>{' '}
                                    en arriendo
                                </li>
                                <li className='list-group-item red text-center'>
                                    <h2
                                        style={{
                                            fontWeight: '1000',
                                        }}
                                    >
                                        Valor INIMITTABLE
                                        <strong>
                                            {' '}
                                            $
                                            {formatMonto(
                                                ordenCompra.valorCupon
                                            )}
                                        </strong>
                                    </h2>
                                    {/* <h3>
                                        Acumulas{' '}
                                        <span className='font-weight-bold'>
                                            {' '}
                                            {ordenCompra.millas} Millas LATAM
                                            Pass
                                        </span>
                                    </h3> */}
                                    <h5>
                                        <a href='/'>
                                            <img
                                                src={require(`../assets/img/back-arrow.png`)}
                                                alt=''
                                            />
                                            Volver página cupones
                                        </a>
                                    </h5>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='col-md-6 mb-4 '>
                        <div className='col-md-12 pb-3 text-center'>
                            <h4>CUPÓN DESCUENTO PARA ARRIENDO MITTA </h4>{' '}
                        </div>
                        <div className='col-md-12 pb-3'>
                            {' '}
                            <h1>
                                <strong>
                                    $
                                    {formatMonto(
                                        ordenCompra.montoDescuentoCupon
                                    )}
                                </strong>
                            </h1>{' '}
                        </div>
                        <div className='col-md-12 detalle_valor_cyberday p-2'>
                            {' '}
                            VALOR INIMITABLE $
                            {formatMonto(ordenCompra.valorCupon)}{' '}
                            <span className='iva'>(IVA incluido)</span>{' '}
                        </div>
                        <div className='col-md-12 pt-3'>
                            <h6>
                                <strong>
                                    (Incluye dcto.{' '}
                                    {ordenCompra.porcentajeDescuentoCupon}%)
                                    STOCK {formatMonto(ordenCompra.stockCupon)}
                                </strong>
                            </h6>
                        </div>
                        <div className='col-md-12 stock'>
                            <ul className='display-6'>
                                <li>
                                    Se puede utilizar sólo un cupón por
                                    arriendo. Válido para personas naturales.
                                </li>
                                <li>
                                    Descuento aplica sobre cualquier tarifa. No
                                    válido para convenios empresas.
                                </li>
                                <li className='red font-weight-bold'>
                                    Vigencia del cupón entre{' '}
                                    {campania.vigenciaDesdeShortString} y el{' '}
                                    {campania.vigenciaHastaShortString}.
                                </li>
                                <li className='red font-weight-bold'>
                                    Este cupón no es válido como reserva de tu
                                    arriendo.{' '}
                                    <a
                                        href='https://www.mitta.cl/reservas'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Reserva aquí.
                                    </a>
                                </li>
                                <li>Sólo arriendos efectuados en Chile.</li>
                            </ul>
                            <a
                                href='https://www.mitta.cl/terminos-legales-arriendo'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Ver Requisitos de Arriendo
                            </a>

                            <br />

                            <a
                                href='https://marketing.mitta.cl/baseslegales'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Bases Legales
                            </a>
                        </div>
                        <div className='col-md-12 detalle_boton'>
                            <AnchorLink href='#pago'>
                                <button className='btn_pagar p-2'>
                                    IR A PAGAR
                                </button>
                            </AnchorLink>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container mt-5 mb-5' id='pago'>
                <form
                    action={process.env.REACT_APP_HUB_PAGO_APP_URL}
                    method='post'
                    onSubmit={handleSubmit(onSubmit)}
                    ref={form}
                    noValidate
                >
                    <div className='row'>
                        <div className='col-lg-12 mt-3 mb-3'>
                            <h1>Ingresa tus Datos de Compra</h1>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label htmlFor='nombreCliente'>Nombre*</label>
                                <input
                                    name='nombreCliente'
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    maxLength='50'
                                    onChange={handleChange}
                                    ref={register({ required: true })}
                                />
                                {errors.nombreCliente &&
                                    errors.nombreCliente.type ===
                                        'required' && (
                                        <span className='campos_requeridos'>
                                            {CAMPO_REQUERIDO}
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label htmlFor='apellidoCliente'>
                                    Apellido*
                                </label>
                                <input
                                    name='apellidoCliente'
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    maxLength='50'
                                    onChange={handleChange}
                                    ref={register({ required: true })}
                                />
                                {errors.apellidoCliente &&
                                    errors.apellidoCliente.type ===
                                        'required' && (
                                        <span className='campos_requeridos'>
                                            {CAMPO_REQUERIDO}
                                        </span>
                                    )}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label htmlFor='rutCliente'>Rut*</label>
                                <input
                                    name='rutCliente'
                                    type='text'
                                    className='form-control'
                                    placeholder='99.999.999-9'
                                    maxLength='15'
                                    onKeyUp={handleKeyUp}
                                    ref={register({
                                        required: true,
                                        validate: {
                                            valid: (value) => validarRut(value),
                                        },
                                    })}
                                />
                                {errors.rutCliente &&
                                    errors.rutCliente.type === 'required' && (
                                        <span className='campos_requeridos'>
                                            {CAMPO_REQUERIDO}
                                        </span>
                                    )}
                                {errors.rutCliente &&
                                    errors.rutCliente.type === 'valid' && (
                                        <span className='campos_requeridos'>
                                            {RUT_NO_VALIDO}
                                        </span>
                                    )}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label htmlFor='direccionCliente'>Dirección*</label>
                            <input
                                name='direccionCliente'
                                type='text'
                                className='form-control'
                                placeholder=''
                                maxLength='100'
                                ref={register({
                                    required: true,
                                })}
                            />
                            {errors.direccionCliente && (
                                <span className='campos_requeridos'>
                                    {CAMPO_REQUERIDO}
                                </span>
                            )}
                        </div>

                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label htmlFor='idRegionCliente'>Región*</label>
                                <select
                                    name='idRegionCliente'
                                    className='form-control'
                                    onChange={handleChange}
                                    ref={register({
                                        required: true,
                                        validate: {
                                            greaterThanZero: (value) =>
                                                Number(value) > 0,
                                        },
                                    })}
                                >
                                    <option value='0'>-- Seleccione --</option>
                                    {regiones.map((region) => (
                                        <option
                                            key={region.id}
                                            value={region.id}
                                        >
                                            {region.nombre}
                                        </option>
                                    ))}
                                </select>
                                {errors.idRegionCliente && (
                                    <span className='campos_requeridos'>
                                        {CAMPO_REQUERIDO}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label htmlFor='idComunaCliente'>Comuna*</label>
                                <select
                                    name='idComunaCliente'
                                    className='form-control'
                                    onChange={handleChange}
                                    ref={register({
                                        required: true,
                                        validate: {
                                            greaterThanZero: (value) =>
                                                Number(value) > 0,
                                        },
                                    })}
                                >
                                    <option value='0'>-- Seleccione --</option>
                                    {comunas.map((comuna) => (
                                        <option
                                            key={comuna.id}
                                            value={comuna.id}
                                        >
                                            {comuna.nombre}
                                        </option>
                                    ))}
                                </select>
                                {errors.idComunaCliente && (
                                    <span className='campos_requeridos'>
                                        {CAMPO_REQUERIDO}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label htmlFor='mailCliente'>Email*</label>
                                <input
                                    name='mailCliente'
                                    type='email'
                                    className='form-control'
                                    placeholder='mitta@mitta.cl'
                                    maxLength='100'
                                    ref={register({
                                        required: true,
                                        validate: {
                                            valid: (value) =>
                                                validarEmail(value),
                                        },
                                    })}
                                />
                                {errors.mailCliente &&
                                    errors.mailCliente.type === 'required' && (
                                        <span className='campos_requeridos'>
                                            {CAMPO_REQUERIDO}
                                        </span>
                                    )}
                                {errors.mailCliente &&
                                    errors.mailCliente.type === 'valid' && (
                                        <span className='campos_requeridos'>
                                            {EMAIL_NO_VALIDO}
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label htmlFor='confirmacionMailCliente'>
                                    Confirmar email*
                                </label>
                                <input
                                    name='confirmacionMailCliente'
                                    type='email'
                                    className='form-control'
                                    placeholder=''
                                    maxLength='100'
                                    ref={register({
                                        required: true,
                                        validate: {
                                            valid: (value) =>
                                                validarEmail(value),
                                            equal: (value) =>
                                                value === watch('mailCliente'),
                                        },
                                    })}
                                />
                                {errors.confirmacionMailCliente &&
                                    errors.confirmacionMailCliente.type ===
                                        'required' && (
                                        <span className='campos_requeridos'>
                                            {CAMPO_REQUERIDO}
                                        </span>
                                    )}
                                {errors.confirmacionMailCliente &&
                                    errors.confirmacionMailCliente.type ===
                                        'valid' && (
                                        <span className='campos_requeridos'>
                                            {EMAIL_NO_VALIDO}
                                        </span>
                                    )}
                                {errors.confirmacionMailCliente &&
                                    errors.confirmacionMailCliente.type ===
                                        'equal' && (
                                        <span className='campos_requeridos'>
                                            {EMAIL_NO_COINCIDE}
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label htmlFor=''>Teléfono Móvil*</label>
                                <input
                                    name='celularCliente'
                                    type='text'
                                    className='form-control'
                                    placeholder='+56 9'
                                    maxLength='12'
                                    ref={register({
                                        required: true,
                                        validate: {
                                            valid: (value) =>
                                                validarCelular(value),
                                        },
                                    })}
                                />
                                {errors.celularCliente &&
                                    errors.celularCliente.type ===
                                        'required' && (
                                        <span className='campos_requeridos'>
                                            {CAMPO_REQUERIDO}
                                        </span>
                                    )}
                                {errors.celularCliente &&
                                    errors.celularCliente.type === 'valid' && (
                                        <span className='campos_requeridos'>
                                            {CELULAR_NO_VALIDO}
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='form-group'>
                                <input
                                    name='deseaRecibirInfo'
                                    className='form-check-input'
                                    type='checkbox'
                                    ref={register}
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor='gridCheck'
                                >
                                    {' '}
                                    Deseo recibir información y promociones de
                                    MITTA en este correo electrónico{' '}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className='row datos_obligatorios'>
                        <div className='col-md-4'>
                            <label className='' htmlFor=''>
                                *Campos Obligatorios
                            </label>
                        </div>
                        <div className='col-md-4'> &nbsp; </div>
                        <div className='col-md-4'>
                            <input
                                name='aceptaTerminos'
                                className='form-check-input'
                                type='checkbox'
                                ref={register({
                                    required: true,
                                })}
                            />
                            <label
                                className='form-check-label'
                                htmlFor='gridCheck'
                            >
                                Acepto{' '}
                                <a
                                    href='https://www.mitta.cl/terminos-legales-arriendo'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Términos y Condiciones de arriendo
                                </a>
                            </label>
                            {errors.aceptaTerminos && (
                                <span className='campos_requeridos'>
                                    {CAMPO_REQUERIDO}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-4'> &nbsp; </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label className='' htmlFor=''>
                                    <strong>VALOR A PAGAR</strong>
                                </label>
                                <br />
                                <input
                                    className='valor_total form-control'
                                    type='text'
                                    id='apagar'
                                    name='apagar'
                                    disabled=''
                                    value={`$${formatMonto(
                                        ordenCompra.valorCupon
                                    )}`}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <br />
                                <button
                                    type='submit'
                                    className='btn_pagar p-2 mt-2'
                                >
                                    Confirmar Compra
                                </button>
                            </div>
                        </div>
                    </div>

                    {/** Datos para hub de pago que se envían por post */}
                    <input
                        type='hidden'
                        name='nombre'
                        value={`${ordenCompra.nombreCliente} ${ordenCompra.apellidoCliente}`}
                    />
                    <input
                        type='hidden'
                        name='ordenCompra'
                        value={ordenCompra.ocCliente}
                    />
                    <input
                        type='hidden'
                        name='monto'
                        value={ordenCompra.valorCupon}
                    />
                    <input
                        type='hidden'
                        name='idCliente'
                        value={process.env.REACT_APP_HUB_PAGO_ID_CLIENTE}
                    />
                    <input
                        type='hidden'
                        name='documentos'
                        value={JSON.stringify(documentos)}
                    />
                </form>
            </section>

            <CampaniaContext.Provider value={campania}>
                <Carrucel />
                <Ayuda />
            </CampaniaContext.Provider>

            {/* Recaptcha */}
            <Recaptcha
                ref={recaptcha}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            />
        </>
    );
};

export default Cupon;
