import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumb = ({ step }) => {

    return (
        <>
            <section className="botonera_up">
            <div className="row">
                <div className="col-md-12">
                    <div className="col-md-4">
                        <Link to="/">
                            <button className={"btn_up " + (step === 1 ? 'activo' : '')}>SELECCIONAR CUPÓN</button>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <button className={"btn_up " + (step === 2 ? 'activo' : '')} style={{ cursor: 'default' }}>DETALLE DEL PRODUCTO</button>
                    </div>
                    <div className="col-md-4">
                        <button className={"btn_up " + (step === 3 ? 'activo' : '')} style={{ cursor: 'default' }}>CONFIRMACIÓN DE COMPRA</button>
                    </div>
                </div>
                </div>
            </section>
        </>
    );
}

export default BreadCrumb;