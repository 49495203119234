import React, { useContext } from 'react';
import faqs01Image from '../assets/img/faqs01.jpg';
import faqs02Image from '../assets/img/faqs02.jpg';
import faqs03Image from '../assets/img/faqs03.jpg';
import faqs04Image from '../assets/img/faqs04.jpg';
import CampaniaContext from '../contexts/campaniaContext';

const Ayuda = (props) => {
    const campaniaContext = useContext(CampaniaContext);

    const handleCallMeClick = (e) => {
        e.preventDefault();

        const cfElement = document.getElementById('clickfono-widget');

        if (cfElement) {
            cfElement.click();
        }
    };
    return (
        <>
            <section className='titulo_interno2'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='col-md-12'>
                            <h2 className='titulo_dentro2'>
                                ¿Necesitas ayuda?
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className='faqs'>
                <div className='row'>
                    <div className='col-md-6 faq2'>
                        <div className='row img_faq'>
                            <img
                                src={faqs01Image}
                                width='30'
                                height='auto'
                                alt=''
                            />
                        </div>
                        <div className='row titulo_faq text-center'>
                            <h3>
                                <strong>
                                    ¿A partir de qué fecha puedo hacer uso de mi
                                    cupón de descuento?
                                </strong>
                            </h3>
                        </div>
                        <div className='row txt_faq text-justify'>
                            Los cupones de descuento se pueden utilizar entre el{' '}
                            {campaniaContext.vigenciaDesdeLongString} y el{' '}
                            {campaniaContext.vigenciaHastaLongString} y se
                            aplica sobre el valor total de su arriendo. Para
                            hacer efectivo el descuento sólo debe ingresar el
                            número del cupón en el campo correspondiente si hace
                            su reserva vía Web o indicar el número al operador
                            en caso de llamar a nuestra central de reservas.
                            Recuerde que puede comprar todos los cupones que
                            desee pero sólo se puede ocupar uno por arriendo. Se
                            pueden transferir a un familiar, amigo o tercero
                            indicando siempre el nombre y Rut del comprador.
                        </div>
                    </div>
                    <div className='col-md-6 faq2'>
                        <div className='row img_faq'>
                            <img
                                src={faqs02Image}
                                width='30'
                                height='auto'
                                alt=''
                            />
                        </div>
                        <div className='row titulo_faq text-center'>
                            <h3>
                                <strong>
                                    ¿Cuál es el plazo de devolución en caso de
                                    desistir de la compra del cupón?
                                </strong>
                            </h3>
                        </div>
                        <div className='row txt_faq text-justify'>
                            <p>
                                El plazo es de 10 días corridos desde el momento
                                de la compra y para obtener su devolución deberá
                                contactarse con{' '}
                                <a href='mailto:servicioalcliente@mitta.cl'>
                                    Servicio al Cliente
                                </a>{' '}
                                o al teléfono 800370111. Tenga presente que{' '}
                                <b>no hay devolución</b> por montos parciales no
                                utilizados producto de no haber completado los
                                días de arriendo contratados.
                            </p>
                        </div>

                        <div className='row justify-content-center'>
                            <p>
                                <button
                                    className='btn_pagar p-2 mt-2'
                                    onClick={handleCallMeClick}
                                >
                                    Te llamamos
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 faq2'>
                        <div className='row img_faq'>
                            <img
                                src={faqs03Image}
                                width='30'
                                height='auto'
                                alt=''
                            />
                        </div>
                        <div className='row titulo_faq text-center'>
                            <h3>
                                <strong>
                                    ¿El cupón me exime de dejar garantía antes
                                    del arriendo?
                                </strong>
                            </h3>
                        </div>
                        <div className='row txt_faq text-justify'>
                            <p>
                                La adquisición de un cupón de descuento{' '}
                                <b>no exime</b> al cliente de este requisito. Al
                                momento de presentarse a retirar el vehículo se
                                deberá garantizar en su tarjeta de crédito un
                                monto mínimo de $450.000 o superior dependiendo
                                del valor total del arriendo y de la categoría
                                del vehículo. Camionetas, minivan y SUV
                                requieren una garantía mínima de $700.000.- o
                                superior según corresponda. Para arriendos en
                                Coyhaique y Punta Arenas se solicitará un cupo
                                mínimo de 20 UF + IVA. Recuerde que sólo se
                                aceptan tarjetas de crédito como garantía.
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6 faq2'>
                        <div className='row img_faq'>
                            <img
                                src={faqs04Image}
                                width='30'
                                height='auto'
                                alt=''
                            />
                        </div>
                        <div className='row titulo_faq text-center'>
                            <h3>
                                <strong>
                                    ¿Los arriendos con cupón incluyen algún tipo
                                    de cobertura de colisión?
                                </strong>
                            </h3>
                        </div>
                        <div className='row txt_faq text-justify'>
                            <p>
                                {' '}
                                Los arriendos incluyen la cobertura de colisión
                                CDW que se aplica en caso de siniestros con
                                resultado de daños del vehículo arrendado y de
                                terceros. Sólo existen deducibles para arriendos
                                en la XI y XII región.
                                <br />
                                <br />
                                Si requiere más información o tiene alguna otra
                                consulta agradeceremos contactar a{' '}
                                <a href='mailto:servicioalcliente@mitta.cl'>
                                    Servicio al Cliente
                                </a>{' '}
                                fono 800370111.
                            </p>
                        </div>
                        <div className='row justify-content-center'>
                            <p>
                                <button
                                    className='btn_pagar p-2 mt-2'
                                    onClick={handleCallMeClick}
                                >
                                    Te llamamos
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class='faqs'>
                <div class='row'>
                    <div class='col-md-12'>
                        <center>
                            <h1 class='fat' style={{ fontWeight: '600' }}>
                                Cupones de descuento INIMITTABLES
                            </h1>
                        </center>
                        <center>
                            <br />
                            <h3 class='fat' style={{ fontSize: '20px' }}>
                                Aprovecha nuestros cupones de descuento en el
                                arriendo de autos para disfrutar de unas
                                vacaciones a un precio inigualable.
                            </h3>
                            <br />
                        </center>
                    </div>
                    <div class='col-md-12 col-md-12'>
                        <div class='row txt_faq text-justify'>
                            El Cupones Inimitables transforman la manera en que
                            arriendas vehículos, poniendo a tu alcance la
                            tecnología más avanzada y un servicio al cliente
                            impecable. Desde la comodidad de tu hogar, puedes
                            explorar nuestra extensa flota de autos modernos y
                            bien equipados, aprovechar nuestros cupones de
                            descuento y disfrutar de tarifas especiales para el
                            verano.
                        </div>
                        <p style={{ textAlign: 'center' }}>
                            <button
                                className='btn_pagar p-2 mt-2'
                                style={{ width: 'auto' }}
                                onClick={handleCallMeClick}
                            >
                                Te llamamos
                            </button>
                        </p>
                    </div>
                    <div class='col-md-12 faq2'>
                        <center>
                            <h2>
                                Aprovecha la oportunidad única y conduce con
                                MITTA
                            </h2>
                        </center>
                        <div class='row txt_faq text-justify'>
                            No importa si necesitas un auto por un día, una
                            semana o más tiempo, nuestras promociones especiales
                            te permitirán acceder a una amplia variedad de
                            vehículos modernos y bien mantenidos. Desde
                            compactos eficientes hasta SUV espaciosos y furgones
                            destacados, en MITTA tenemos el auto perfecto para
                            cada ocasión.
                        </div>
                    </div>
                    <div class='col-md-12 faq2'>
                        <center>
                            <h2>
                                {' '}
                                Cupones INIMITTABLES con ofertas en arriendo de
                                autos que no debes dejar pasar
                            </h2>
                        </center>
                        <div class='row txt_faq text-justify'>
                            Prepárate para descubrir una nueva forma de viajar,
                            donde cada detalle está pensado para ofrecerte la
                            máxima comodidad y seguridad. Con nuestro proceso de
                            reserva en línea, podrás asegurar tu vehículo de
                            forma rápida y sencilla, aprovechando las tarifas
                            más bajas del año. ¡Compra ahora nuestros cupones de
                            descuento y emprende tu próxima aventura con el
                            mejor arriendo de autos del mercado!
                        </div>
                    </div>
                    <div class='col-md-12'>
                        <div class='row'>
                            <div class='accordion'>
                                <div class='accordion-header'>
                                    <h3>
                                        ¿Qué tipo de vehículos ofrecen los
                                        cupones INIMITTABLES?
                                    </h3>
                                </div>
                                <div class='accordion-content'>
                                    <div class='row txt_faq text-justify'>
                                        Los cupones de arriendo en el
                                        INIMITTABLES ponen a tu disposición una
                                        amplia gama de autos que incluye desde
                                        autos compactos económicos hasta
                                        camionetas 4x4 y furgones espaciosos.
                                        Todos nuestros autos son modernos, bien
                                        mantenidos y sometidos a rigurosos
                                        controles de calidad y limpieza.
                                    </div>
                                </div>
                                <div class='accordion-header'>
                                    <h3>
                                        ¿A partir de qué fecha puedo hacer uso
                                        de mi cupón de descuento?{' '}
                                    </h3>
                                </div>
                                <div class='accordion-content'>
                                    <div class='row txt_faq text-justify'>
                                        Los cupones de descuento se pueden
                                        utilizar entre el{' '}
                                        {
                                            campaniaContext.vigenciaDesdeLongString
                                        }{' '}
                                        y el{' '}
                                        {
                                            campaniaContext.vigenciaHastaLongString
                                        }{' '}
                                        y se aplica sobre el valor total de su
                                        arriendo. Para hacer efectivo el
                                        descuento sólo debe ingresar el número
                                        del cupón en el campo correspondiente si
                                        hace su reserva vía Web o indicar el
                                        número al operador en caso de llamar a
                                        nuestra central de reservas. Recuerde
                                        que puede comprar todos los cupones que
                                        desee, pero sólo se puede ocupar uno por
                                        arriendo. Se pueden transferir a un
                                        familiar, amigo o tercero indicando
                                        siempre el nombre y Rut del comprador.
                                    </div>
                                </div>
                                <div class='accordion-header'>
                                    <h3>
                                        ¿Cuál es el plazo de devolución en caso
                                        de desistir de la compra del cupón?{' '}
                                    </h3>
                                </div>
                                <div class='accordion-content'>
                                    <div class='row txt_faq text-justify'>
                                        El plazo es de 10 días corridos desde el
                                        momento de la compra y para obtener su
                                        devolución deberá contactarse con
                                        Servicio al Cliente
                                        servicioalcliente@mitta.cl o al teléfono
                                        800370111. Tenga presente que no hay
                                        devolución por montos parciales no
                                        utilizados producto de no haber
                                        completado los días de arriendo
                                        contratados.
                                    </div>
                                    <p style={{ textAlign: 'center' }}>
                                        <button
                                            className='btn_pagar p-2 mt-2'
                                            style={{ width: 'auto' }}
                                            onClick={handleCallMeClick}
                                        >
                                            Te llamamos
                                        </button>
                                    </p>
                                </div>
                                <div class='accordion-header'>
                                    <h3>
                                        ¿El cupón me exime de dejar garantía
                                        antes del arriendo?{' '}
                                    </h3>
                                </div>
                                <div class='accordion-content'>
                                    <div class='row txt_faq text-justify'>
                                        La adquisición de un cupón de descuento
                                        no exime al cliente de este requisito.
                                        Al momento de presentarse a retirar el
                                        vehículo se deberá garantizar en su
                                        tarjeta de crédito un monto mínimo de
                                        $450.000 o superior dependiendo del
                                        valor total del arriendo y de la
                                        categoría del vehículo. Camionetas,
                                        minivan y SUV requieren una garantía
                                        mínima de $700.000.- o superior según
                                        corresponda. Para arriendos en Coyhaique
                                        y Punta Arenas se solicitará un cupo
                                        mínimo de 20 UF + IVA. Recuerde que sólo
                                        se aceptan tarjetas de crédito como
                                        garantía.
                                    </div>
                                </div>
                                <div class='accordion-header'>
                                    <h3>
                                        ¿Los arriendos con cupón incluyen algún
                                        tipo de cobertura de colisión?{' '}
                                    </h3>
                                </div>
                                <div class='accordion-content'>
                                    <div class='row txt_faq text-justify'>
                                        Los arriendos incluyen la cobertura de
                                        colisión CDW que se aplica en caso de
                                        siniestros con resultado de daños del
                                        vehículo arrendado y de terceros. Sólo
                                        existen deducibles para arriendos en la
                                        XI y XII región.
                                        <br />
                                        <br />
                                        <span style={{ display: 'block' }}>
                                            Si requiere más información o tiene
                                            alguna otra consulta agradeceremos
                                            contactar a{' '}
                                            <a href='mailto:servicioalcliente@mitta.cl'>
                                                Servicio al Cliente
                                            </a>{' '}
                                            o al teléfono 800370111.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Ayuda;
