import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import cuponesApi from './../services/api/cuponesApi';
import { formatMonto } from './../utils/numeroUtils';
import Loader from '../components/Loader';
import CampaniaContext from '../contexts/campaniaContext';

const HomeCupones = () => {
    const [cupones, setCupones] = useState([]);
    const [loading, setLoading] = useState(false);
    const campaniaContext = useContext(CampaniaContext);

    useEffect(() => {
        fetchCupones();
    }, []);

    const fetchCupones = async () => {
        try {
            setLoading(true);

            const response = await cuponesApi.getByCampania(
                process.env.REACT_APP_ID_CAMPANIA
            );
            setCupones(response.data);
        } catch {
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <Loader />}

            <section id='cupones' className='container-fluid texto_llamada'>
                <div className='row'>
                    <div className='col-md-12'>
                        <center>
                            <br />
                            <h2 className='fat'>
                                ¡Anticipa tus vacaciones con nuestros cupones
                                INIMITTABLES!
                                <br />
                                <p className='pt-2'>
                                    <span className='red'>
                                        35% de descuento{' '}
                                    </span>
                                    en tu cupón de arriendo
                                </p>
                            </h2>
                            <br />
                        </center>
                    </div>
                </div>
            </section>

            <section className='container'>
                <div className='row'>
                    {cupones.map((cupon) => (
                        <div
                            className={`col-md-6 mb-4 ${
                                cupon.hasStock ? '' : 'filtro-gris'
                            }`}
                            key={cupon.id}
                        >
                            <div className='card mx-auto'>
                                <img
                                    src={`${process.env.PUBLIC_URL}/img/${cupon.image}`}
                                    className='card-img-top'
                                    alt='Cupón de descuento Mitta'
                                />

                                <ul className='list-group list-group-flush'>
                                    <li className='list-group-item bg-secondary text-white text-center'>
                                        Cupón{' '}
                                        <strong>
                                            ${formatMonto(cupon.montoDescuento)}
                                        </strong>{' '}
                                        en arriendo
                                    </li>
                                    <li className='list-group-item red text-center'>
                                        <h2
                                            style={{
                                                fontWeight: '1000',
                                            }}
                                        >
                                            Valor INIMITTABLE
                                            <span className='font-weight-bold'>
                                                {' '}
                                                ${formatMonto(cupon.valorCupon)}
                                            </span>
                                        </h2>
                                        {/* <h4>
                                            Acumulas
                                            <span className='font-weight-bold'>
                                                {' '}
                                                {cupon.millas} Millas LATAM Pass
                                            </span>
                                        </h4> */}

                                        {cupon.hasStock && (
                                            <Link to={`/cupon/${cupon.codigo}`}>
                                                <button className='btn-red mt-2'>
                                                    Comprar
                                                </button>
                                            </Link>
                                        )}
                                        {!cupon.hasStock && (
                                            <button
                                                className='btn-red mt-2'
                                                disabled
                                            >
                                                Agotado
                                            </button>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className='row'>
                    <div className='col-md-12 text-center logo-latam'>
                        <img
                            src={require(`../assets/img/latam-pass.png`)}
                            alt='LATAM Pass'
                        />
                    </div>
                </div> */}
            </section>

            <section className='nota_pie'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='col-md-12 nota_dentro'>
                            <p className='ml-3 mb-1'>Consideraciones:</p>
                            <ul>
                                <li>
                                    Se puede utilizar sólo un cupón por
                                    arriendo. Válido para personas naturales.
                                </li>
                                <li>
                                    Descuento aplica sobre cualquier tarifa. No
                                    válido para convenios empresas.
                                </li>
                                <li className='red font-weight-bold'>
                                    Vigencia entre el{' '}
                                    {campaniaContext.vigenciaDesdeShortString} y
                                    el{' '}
                                    {campaniaContext.vigenciaHastaShortString}.
                                    Sólo arriendos efectuados en Chile.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeCupones;
