import React from 'react';
import loadingImage from './../assets/img/loading.gif';
import './../assets/css/loading.css';

const Loader = (props) => {
    const { message } = props;

    return (
        <div className="loading" style={{ display: "block" }}>
            <div className="txt">
                <img src={loadingImage} alt="loading" />
                {message && <div id="msg-load"><h3><strong>Usted será redirigido al resumen de su compra</strong></h3></div>}
            </div>
        </div>
    );
}

export default Loader;