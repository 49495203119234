import React, { useState } from 'react';
import HomeBanner from './HomeBanner';
import HomeCupones from './HomeCupones.jsx';
import Carrucel from '../components/Carrusel';
import Ayuda from '../components/Ayuda';
import campaniaApi from '../services/api/campaniaApi';
import CampaniaContext from '../contexts/campaniaContext';

const Home = () => {
    const [campania, setCampania] = useState({
        vigenciaDesdeShortString: '',
        vigenciaHastaShortString: '',
        vigenciaDesdeLongString: '',
        vigenciaHastaLongString: '',
    });

    useState(() => {
        campaniaApi.getById(process.env.REACT_APP_ID_CAMPANIA).then((response) => {
            if (response.data) {
                const options = { year: 'numeric', month: 'long', day: 'numeric' };

                setCampania({
                    ...campania,
                    vigenciaDesdeShortString: new Date(response.data.vigenciaDesde).toLocaleDateString('es-CL'),
                    vigenciaHastaShortString: new Date(response.data.vigenciaHasta).toLocaleDateString('es-CL'),
                    vigenciaDesdeLongString: new Date(response.data.vigenciaDesde).toLocaleDateString('es-CL', options),
                    vigenciaHastaLongString: new Date(response.data.vigenciaHasta).toLocaleDateString('es-CL', options),
                });
            }
        });
    }, []);

    return (
        <>
            <CampaniaContext.Provider value={campania}>
                <HomeBanner />
                <HomeCupones />
                <Carrucel />
                <Ayuda />
            </CampaniaContext.Provider>
        </>
    );
};

export default Home;
