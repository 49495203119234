export const formatMonto = (monto) => {
    if (monto) {
        const number = Number(monto);

        if (!isNaN(number))
            return number.toLocaleString('es-CL');
        else
            return '';
    }
    else {
        return '';
    }
}