import React from 'react';
import logoFooterImage from './../assets/img/logotipo-mitta-rentacar.jpg';
import footerWebPayImage from './../assets/img/fotter_webpay.jpg';
import fbImage from './../assets/img/facebook-mitta-rentacar.jpg';
import igImage from './../assets/img/instagram-mitta-rentacar.jpg';
import inImage from './../assets/img/linkedin-mitta-rentacar.jpg';

const Footer = () => {
    return (
        <footer className='pie'>
            <div className='row'>
                <div className='col-md-3'>
                    <a href='https://www.mitta.cl'>
                        <img
                            src={logoFooterImage}
                            width='100%'
                            height='auto'
                            alt='Cupón de descuento Mitta'
                        />
                    </a>
                </div>
                <div className='col-md-3'>
                    <div className='col-md-12'>
                        <strong>SERVICIO AL CLIENTE</strong>
                        <br />
                        <span className='tel'>
                            <a href='tel:800370111' className='click_call'>
                                800 370 111
                            </a>
                        </span>
                        <br />
                        <a href='mailto:servicioalcliente@mitta.cl'>
                            servicioalcliente@mitta.cl
                        </a>
                    </div>
                    <div className='col-md-12'>
                        RESERVAS
                        <br />
                        <span className='tel'>
                            <a href='tel:223608666' className='click_call'>
                                22 360 86 66
                            </a>
                        </span>
                        <br />
                        <a href='mailto:reservasnac@mitta.cl'>
                            reservasnac@mitta.cl
                        </a>
                    </div>
                    <div className='col-md-12'>
                        SOPORTE CYBER MITTA
                        <br />
                        <a href='mailto:soporteweb@mitta.cl'>
                            soporteweb@mitta.cl
                        </a>
                    </div>
                    <div className='col-md-12'>
                        <a href='https://www.mitta.cl/terminos-legales-arriendo'>
                            Términos y Condiciones
                        </a>
                    </div>
                </div>
                <div className='col-md-3 linea'>
                    <a href='https://www.mitta.cl'>
                        <strong>MITTA.CL</strong>
                    </a>
                    <br />
                    <br />
                    <a href='https://www.mitta.cl'>Inicio</a>
                    <br />
                    <a href='https://www.mitta.cl/datos-de-reserva'>Reservas</a>
                    <br />
                    <a href='https://www.mitta.cl/beneficios'>Beneficios</a>
                    <br />
                    <a href='https://www.mitta.cl/rent-car'>Rent a car</a>
                    <br />
                    <a href='https://www.mitta.cl/leasing-operativo'>
                        Leasing Operativo
                    </a>
                    <br />
                    <a href='https://www.mitta.cl/sucursales'>Sucursales</a>
                    <br />
                    <a href='https://www.mitta.cl/politica-de-confidencialidad'>
                        Política de Confidencialidad
                    </a>
                    <br />
                    <a href='https://www.mitta.cl/terminos-de-uso'>
                        Términos de Uso
                    </a>
                    <br />
                    <a href='https://www.mitta.cl/contactanos'>Contáctenos</a>
                    <br />
                    <a href='https://www.mitta.cl/preguntas-frecuentes-3'>
                        Preguntas Frecuentes
                    </a>
                    <br />
                </div>
                <div className='col-md-3'>
                    <div className='col-md-12'></div>
                    <div className='col-md-12'>
                        Medios de Pago
                        <br />
                        <img
                            src={footerWebPayImage}
                            width='100%'
                            height='auto'
                            alt=''
                        />{' '}
                    </div>
                    <div className='col-md-12 rrss'>
                        Síguenos en
                        <br />
                        <a href='https://www.facebook.com/Mittarentacar'>
                            <img
                                src={fbImage}
                                width='28'
                                height='auto'
                                alt='Cupón de descuento Mitta'
                            />
                        </a>
                        <a href='https://www.instagram.com/mittarentacar'>
                            <img
                                src={igImage}
                                width='28'
                                height='auto'
                                alt='Cupón de descuento Mitta'
                            />
                        </a>
                        <a href='https://www.linkedin.com/company/mittarentacar'>
                            <img
                                src={inImage}
                                width='28'
                                height='auto'
                                alt='Cupón de descuento Mitta'
                            />
                        </a>
                    </div>
                </div>

                <div className='col-md-12 colofon'>
                    Seguridad y Privacidad Garantizada Sectigo RSA. Comprar en{' '}
                    <strong>Mitta.cl</strong> es 100% seguro.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
