import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import cdOficialImage from '../assets/img/cd_oficial.png';
import logoCyberWhiteImage from '../assets/img/logo-cyber-white.svg';
import cincuentaImage from '../assets/img/45.svg';
import logotipoCyberImage from '../assets/img/logotipo_cyber.svg';

const HomeBanner = () => {
    return (
        <>
            <div className='row altura_base finals banner-landing position-relative'>
                {/* <!-- ////// ELEMENTOS ALINEADOS A LA DERECHA - CUPON 50% ////// --> */}
                <div class='col-md-4 float-left text-center m-4 view-a top-cupon left-cupon'>
                    <div class='mt-4'>
                        <img
                            src={`${process.env.PUBLIC_URL}/img/landing-img-1.png`}
                        />
                    </div>
                    <div class='mt-4'>
                        <img
                            src={`${process.env.PUBLIC_URL}/img/landing-img-2.png`}
                        />
                    </div>

                    {/* <h2 className='text-uppercase text-white font-weight-bold h-txt '>
                        Anticípate incluso
                        <br />
                        en las últimas horas
                        <br />
                        del #CyberMitta
                    </h2> */}
                    {/* <div className='mt-4'>
                        <AnchorLink href='#cupones'>
                            <button className='btn_banner_ver'>
                                VER CUPONES
                            </button>
                        </AnchorLink>
                    </div> */}
                </div>

                {/* <!-- ////// FIN////// --> */}

                {/* <!-- ////// Rent a Car Leasing Operativo ////// --> */}
                <div className='col col_20 cot_container_radio left semi-circle'>
                    <div className='col_60'>
                        <div className='title-circle'>
                            Rent a
                            <br /> Car
                        </div>
                    </div>
                </div>
                {/* <!-- ////// FIN ////// --> */}

                {/* <div className='col-md-6 barra-inferior-border pb-2 no-view-a'>
                    <div>
                        <img
                            className='img-10 pb-2'
                            src={logotipoCyberImage}
                            alt=''
                        />{' '}
                        <img
                            className='img-20 pb-3'
                            src={logoCyberWhiteImage}
                            alt=''
                        />{' '}
                        <span className='text-white w-50 rounded-lg border border-white ml-3'>
                            30 de septiembre, 01 y 02 de octubre
                        </span>
                    </div>
                </div> */}

                <div class='mobile-text'>
                    {/* <h2 class='text-uppercase text-white font-weight-bold h-txt '>
                        Anticípate incluso
                        <br />
                        en las últimas horas
                        <br />
                        del #CyberMitta
                    </h2> */}
                    {/* <div class='mt-0'>
                        <a href='#cupones'>
                            <button class='btn_banner_ver'>VER CUPONES</button>
                        </a>
                    </div> */}
                </div>
            </div>

            {/* <!-- ////// ELEMENTOS RESPONSIVE TABLET A MOVIL ////// --> */}
            {/* <div className='row  view-b no-view-b mb-3'>
                <div className='col-lg-6 text-center blue-cyber'>
                    <img
                        className='img-fluid w-25 pt-2 pb-2 pr-3 b-right'
                        src={cdOficialImage}
                        alt=''
                    />
                    <img
                        className='img-fluid w-50 pb-2 pl-3'
                        src={logoCyberWhiteImage}
                        alt=''
                    />
                </div>
            </div> */}
            {/* <!-- ////// ELEMENTOS RESPONSIVE TABLET A MOVIL END ////// --> */}
        </>
    );
};

export default HomeBanner;
