import api from '../../config/api';

const getByRegion = async (id) => {

    try {
        const response = await api.mainAxios.get(`/Comuna/GetByRegionAsync/${id}`);

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};


export default {
    getByRegion
};