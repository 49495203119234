import React from 'react';
import { Link } from 'react-router-dom';
import logoCyberWhiteImage from '../assets/img/logo-cyber-white.svg';
import logotipoCyberImage from '../assets/img/logotipo_cyber.svg';

const Error = ({ error }) => {
    return (
        <>
            <div className='row altura_base finals banner-landing position-relative'>
                {/* <!-- ////// ELEMENTOS ALINEADOS A LA DERECHA - CUPON 50% ////// --> */}
                <div className='col-md-4 float-right text-center text-right  m-4 view-a no-view-a top-ultimahora'>
                    <h1 className='text-white display-3 font-weight-bold'>
                        ¡UPS...!
                    </h1>

                    <h1 className='text-white font-weight-bold pb-3 txt-ultimahora'>
                        Lo sentimos hubo un
                        <br />
                        error en tu pago
                    </h1>
                </div>

                {/* <!-- ////// FIN////// --> */}

                {/* <!-- ////// Rent a Car Leasing Operativo ////// --> */}
                <div className='col col_20 cot_container_radio left semi-circle'>
                    <div className='col_60'>
                        <div className='title-circle'>
                            Rent a
                            <br />
                            Car
                        </div>
                    </div>
                </div>
                {/* <!-- ////// FIN ////// --> */}

                {/* <div className='col-md-4 barra-inferior2-border pb-4 no-view-a text-center pr-5'>
                    <div>
                        <img
                            className='img-15 pb-2'
                            src={logotipoCyberImage}
                            alt=''
                        />{' '}
                        <img
                            className='img-40 pb-3'
                            src={logoCyberWhiteImage}
                            alt=''
                        />
                    </div>
                    <span className='w-50 text-white w-50 rounded-lg border border-white ml-3'>
                        30 de septiembre, 01 y 02 de octubre
                    </span>
                </div> */}
            </div>

            {/* <!-- ////// ELEMENTOS RESPONSIVE TABLET A MOVIL ////// --> */}
            <div className='row probando view-b no-view-b'>
                {/* <div className='col-lg-6 text-center blue-cyber pt-2 pb-2'>
                    <img className='img-fluid w-50 pr-1' src={logoCyberWhiteImage} alt='' />
                    <img className='img-fluid w-25' src={logotipoCyberImage} alt='' />
                </div> */}

                <div className='col-md-12  text-white bg-red text-center pl-4 pr-4 pb-4'>
                    <h1 className='font-weight-bold display-4 text-white txt'>
                        ¡UPS...!
                    </h1>
                    <h3 className='text-white txt'>
                        Lo sentimos hubo un error en tu pago
                    </h3>
                </div>
            </div>
            {/* <!-- ////// ELEMENTOS RESPONSIVE TABLET A MOVIL END ////// --> */}

            <section className='container-fluid bg-negro pt-2'>
                <div className='row'>
                    <div className='col-md-12 text-center mt-3 mb-3'>
                        <Link to='/'>
                            <button className='btn btn-primary text-uppercase  pl-5 pr-5'>
                                Intenta nuevamente
                            </button>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Error;
