import api from './../../config/api';

const getById = async (id) => {
    try {
        const response = await api.mainAxios.get(`/Campanias/${id}`);

        return response;
    } catch (error) {
        console.error('error: ', error);

        throw error;
    }
};

export default {
    getById,
};
