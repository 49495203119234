import api from './../../config/api';

const getAll = async () => {

    try {
        const response = await api.mainAxios.get('/Region/GetAllAsync');

        return response;
    } catch (error) {
        console.error("error: ", error);

        throw error;
    }
};

export default {
    getAll
};