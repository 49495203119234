export const limpiarRut = function (rut) {
  return rut.replace(/\./g, '').replace('-', '');
}

export const formatearRut = (rut) => {
  const formatear = function (input) {
    let rut = limpiarRut(input);

    let sRut1 = rut;      // Contador de para saber cuando insertar el . o el -.
    let nPos = 0; // Guarda el rut invertido con los puntos y el guión agregado.
    let sInvertido = ''; // Guarda el resultado final del rut como debe ser.
    let sRut = '';

    for (let i = sRut1.length - 1; i >= 0; i--) {
      sInvertido += sRut1.charAt(i);

      if (i === sRut1.length - 1)
        sInvertido += '-';
      else if (nPos === 3) {
        sInvertido += '.';
        nPos = 0;
      }

      nPos++;
    }
    for (let j = sInvertido.length - 1; j >= 0; j--) {
      if (sInvertido.charAt(sInvertido.length - 1) !== '.')
        sRut += sInvertido.charAt(j);
      else if (j !== sInvertido.length - 1)
        sRut += sInvertido.charAt(j);
    }

    // Pasamos al campo el valor formateado
    let rutFormateado = sRut.toUpperCase();
    let rutValidar = sRut.toUpperCase();

    rutValidar = limpiarRut(rutValidar);

    let nAcumula = 0;

    //Factor por el cual se debe multiplicar el valor de la posicion
    let nFactor = 2;

    //Dígito verificador
    let nDv = 0;
    let nDvReal = 0;

    // Extraemos el ultimo numero o letra que corresponde al verificador
    // La K corresponde a 10
    if (rutValidar.charAt(rutValidar.length - 1).toUpperCase() === 'K')
      nDvReal = 10;
    //el 0 corresponde a 11
    else if (rutValidar.charAt(rutValidar.length - 1) === "0")
      nDvReal = 11;
    else
      nDvReal = Number(rutValidar.charAt(rutValidar.length - 1));

    for (nPos = rutValidar.length - 2; nPos >= 0; nPos--) {
      nAcumula += rutValidar.charAt(nPos).valueOf() * nFactor;
      nFactor++;

      if (nFactor > 7) nFactor = 2;
    }

    nDv = 11 - (nAcumula % 11)
    
    if (nDv === nDvReal) {       
      if (rut.slice(0,-1).length < 7) {
        return '';
      }

      return rutFormateado;
    }
    else {
      return '';
    }
  };

  return rut ? formatear(rut) : '';
}

export const validarRut = (rut) => {
  return formatearRut(rut) !== '';
}