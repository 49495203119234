import api from './../../config/api';

const getAll = async () => {
    try {
        const response = await api.mainAxios.get('/Cupones/GetAllAsync');

        return response;
    } catch (error) {
        console.error('error: ', error);

        throw error;
    }
};

const getById = async (id) => {
    try {
        const response = await api.mainAxios.get(`/Cupones/${id}`);

        return response;
    } catch (error) {
        console.error('error: ', error);

        throw error;
    }
};

const hasStock = async (id) => {
    try {
        const response = await api.mainAxios.get(`/Cupones/HasStockAsync/${id}`);

        return response;
    } catch (error) {
        console.error('error: ', error);

        throw error;
    }
};

const getByCampania = async (idCampania) => {
    try {
        const response = await api.mainAxios.get(`/campanias/${idCampania}/cupones`);

        return response;
    } catch (error) {
        console.error('error: ', error);

        throw error;
    }
};

const getIdByCodigo = async (idCampania, codigo) => {
    const params = {
        idCampania,
        codigo,
    };

    try {
        const response = await api.mainAxios.get(`/Cupones/GetIdByCodigoAsync`, { params: params });

        return response;
    } catch (error) {
        console.error('error: ', error);

        throw error;
    }
};

export default {
    getAll,
    getById,
    hasStock,
    getByCampania,
    getIdByCodigo,
};
