import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logoImage from './../assets/img/mitta-logo.svg';

const Header = () => {
    return (
        <header className='container-fluid'>
            <div className='row'>
                <div className='col-lg-2 headerlp'>
                    <a href='https://www.mitta.cl'>
                        <img className='logo' src={logoImage} width='300' height='auto' alt='' />
                    </a>
                </div>
                <div className='col-lg-10'>
                    <Navbar className='menu-mitta' bg='white' expand='lg'>
                        <Container>
                            <Navbar.Toggle aria-controls='basic-navbar-nav' />
                            <Navbar.Collapse id='basic-navbar-nav' className='justify-content-lg-end'>
                                <Nav className='me-auto'>
                                    <Nav.Link href='https://www.mitta.cl/datos-de-reserva'>Reservas</Nav.Link>
                                    <Nav.Link href='https://www.mitta.cl/rent-car'>Rent a Car</Nav.Link>
                                    <Nav.Link href='https://www.mitta.cl/leasing-operativo'>Leasing Operativo</Nav.Link>
                                    <Nav.Link href='https://www.mitta.cl/renting'>Renting</Nav.Link>
                                    <Nav.Link href='https://www.mitta.cl/beneficios'>Beneficios</Nav.Link>
                                    <Nav.Link href='https://www.mitta.cl/blog'>Blog</Nav.Link>
                                    <Nav.Link href='https://www.mitta.cl/contactanos'>Contacto</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>
        </header>
    );
};

export default Header;
